import React from 'react';

import { Div } from '@driftt/tide-core';

import './style.css';

function SunsetBanner() {
  return (
    <Div className="sunset-banner">
      <Div>
        <p style={{ fontSize: '16px', fontWeight: '600', margin: 0 }}>
          Drift Video is Sunsetting Soon
        </p>
      </Div>
      <p style={{ margin: 0, marginTop: '8px' }}>
        Reminder that Drift Video will be discontinued on January 31, 2025; be sure to download any
        videos you want to keep. Any videos not saved locally will be lost and irretrievable after
        the sunset. See{' '}
        <a href="https://help.salesloft.com/s/article/Drift-Video-Offboarding">this article</a> or
        reach out to our support team if you have any questions.
      </p>
    </Div>
  );
}

export default SunsetBanner;
